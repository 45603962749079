<template>
  <div class="bg-wrapper">
    <img src="/img/ak.svg" class="bg-svg">
    <img src="/img/ak.svg" class="bg-svg">
    <img src="/img/ak.svg" class="bg-svg">
    <img src="/img/ak.svg" class="bg-svg">
    <img src="/img/ak.svg" class="bg-svg">
    <img src="/img/glock.svg" class="bg-svg">
    <img src="/img/glock.svg" class="bg-svg">
    <img src="/img/glock.svg" class="bg-svg">
    <img src="/img/glock.svg" class="bg-svg">
    <img src="/img/ak.svg" class="bg-svg">
    <img src="/img/ak.svg" class="bg-svg">
    <img src="/img/ak.svg" class="bg-svg">
    <img src="/img/butterfly.svg" class="bg-svg">
    <img src="/img/butterfly.svg" class="bg-svg">
    <img src="/img/butterfly.svg" class="bg-svg">
    <img src="/img/butterfly.svg" class="bg-svg">
    <img src="/img/butterfly.svg" class="bg-svg">
    <img src="/img/butterfly.svg" class="bg-svg">
    <img src="/img/ak.svg" class="bg-svg">
    <img src="/img/ak.svg" class="bg-svg">
  </div>
</template>

<script setup lang="ts">
</script>

<style lang="sass" scoped>
.bg-wrapper
  position: absolute
  top: 0
  bottom: 0
  left: -2rem
  right: -2rem
  overflow: hidden
  z-index: -1

.bg-svg
  width: 10rem
  position: absolute
  transform-style: preserve-3d

@for $i from 1 through 20
  $scale: random(2) - .4

  .bg-svg:nth-child(#{$i})
    left: random(120) * 1%
    animation: raise#{$i} 6 + random(15) +s linear infinite
    animation-delay: random(5) - 5 + s
    z-index: $i - 7
    filter: brightness(0) saturate($i * 5%) invert($i * 4%) sepia($i * 5%) saturate($i * 75%) hue-rotate(357deg) brightness(100%) contrast($i * 5%) blur(random(50) * 0.01rem + 0.3rem)

    @keyframes raise#{$i}
      from
        bottom: -30vh
      to
        bottom: 120vh
        transform: scale(.1 * $i - .3) rotate(random(360) + deg)
</style>
